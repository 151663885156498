:root {
  --black: #000000;
  --blue: #1e90ff;
  --white: #ffffff;
  --grey: #ddd;
  --cream: #f2eeef;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body,h1,h2,h3,h4,h5,h6,a,p,span,strong,input,b{
  font-family: 'Open Sans', sans-serif;
}
/* Home Page */
/* slider_banner */
.carousel-caption {
  top: 35%;
}
.logout_modal button.btn.btn-primary {
  right: 0px;
  margin-left: 8px;
  margin-right: 8px;
}

.slide img {
  height: 600px;
  object-fit: cover;
}
.banner-container {
  margin-top: 70px;
}
.carousel.slide::before {
  content: "";
  background: #00000073;
  position: absolute;
  top: 0;
  height: 600px;
  width: 100%;
  display: block;
  z-index: 99;
}
.carousel-caption.d-none.d-md-block {
  color: #fff !important;
  z-index: 99;
}
.header_button a:hover {
  background: #fff !important;
}
.slide button {
  z-index: 99 !important;
}
.slide span {
  filter: none !important;
}
.slide h1 {
  font-size: 57px !important;
  font-weight: 600;
}
.slide p {
  font-size: 18px;
  width: 80%;
  margin: 36px auto;
}
[data-coreui-theme=dark] .carousel .carousel-indicators [data-coreui-target], [data-coreui-theme=dark].carousel .carousel-indicators [data-coreui-target] {
  background-color: #fff !important;
  z-index: 99999 !important;
  position: relative;
}
.carousel-indicators {
  z-index: 99999 !important;
}
/* slider_end */
.banner-container .banner-heading h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto 30px;
}
.banner-container .button-box button {
  border: none;
}
.banner-container .button-box a {
  color: #fff;
  text-decoration: none;
}
.banner-container .banner-sub-heading h6 {
  font-size: 18px;
  margin: auto;
  line-height: 35px;
}
.radio:last-child .check-boxes {
  margin-top: 20px !important;
}
.form-group input[type=radio] {
  float: right !important;
}
.banner-container .button-box {
  float: left;
}
.banner-container .button-box button {
  padding: 7px 18px;
  border-radius: 8px;
  background-color: var(--black);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
}

.banner-container .banner-img-box {
  margin-top: 70px;
}

.banner-container .banner-img-box .img-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.banner-container .banner-img-box .img-box img {
  width: 100%;
  height: 100%;
}

.banner-container .banner-text-box {
  margin-top: 70px;
  text-align: center;
}

.banner-container .banner-text-box h1 {
  font-size: 40px;
  font-weight: 600;
}

.dropdown .dropdown-item img{
 height: 25px;
 width:25px;
 margin: 10px;

}

.dropdown .dropdown-item {
  padding-bottom: 5px;
  font-size: 15px;
  width: 100%;
  padding-top: 5px;
}
.dropdown .dropdown-item:hover{
 color: white !important;
}


.blog-image {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}


.aboutus-container {
  margin-top: 50px;
  border-radius: 15px;
  padding: 40px 20px;
  background-color: var(--cream);
}

.aboutus-container .left-text-box {
  padding: 20px;
}
button.btn.btn-primary {
  right: 0px;
  margin-left: 376px;
}

.aboutus-container .left-text-box .aboutus-heading {
  margin-bottom: 20px;
}

.aboutus-container .left-text-box .aboutus-heading h1 {
  font-size: 40px;
  font-weight: 600;

}

.aboutus-container .left-text-box .aboutus-content p {
  font-size: 18px;
  margin: 30px 0px;
}

.image-box {
  width: 100%;
  height: 550px;
} 

.blog-image img {
  height: 340px;
  object-fit: cover;
  /* width: 100%; */
}
.start_free {
  padding-top: 30px;
}
.aboutus-container .left-text-box .aboutus-bottom-content p {
  font-size: 20px;
}

.services-container {
  margin-top: 50px;
}

.Itinerary-data button.accordion-button p {
  margin-bottom: 0px;
  margin-left: 4px;
  margin-top: 3px;
}

.Itinerary-data h4 {
  margin-bottom: 0;
  font-size: 20px;
}

.modal.show .modal-dialog {
  transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.logout_modal .modal-dialog.modal-sm {
  right: 28px !IMPORTANT;
  display: block !IMPORTANT;
  top: 48px !important;
  left: auto;
}
.logout_modal .modal_button {
    display: flex;
    justify-content: center;
}
.logout_modal .modal-title.h4 {
  font-size: 20px;
}
.modal-title.h4 {
  margin: auto;
  text-align: center;
  color: green;
  
}
.services-container .service-box-1,
.services-container .service-box-2 {
  padding: 30px 10px;
  text-align: center;
  margin: 19px;
  width: 47%;
}
button#dropdown-basic {
  color: green;
}
.num {
  font-weight: 400;
  font-size: 19px;
}
.service-box-1 img, .service-box-2 img {
  max-width: 60%;
  margin: 50px auto 0;
}
.blog-image .artical h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
}
.blog-image .artical p {
  font-size: 17px;
  margin-top: 20px;
}
.services-container .service-box-1 .heading h1,
.services-container .service-box-2 .heading h1 {
  padding: 30px 10px;
  font-weight: 600;
}

.services-container .service-box-1 .content p,
.services-container .service-box-2 .content p {
  padding: 0px 20px;
  font-size: 18px;
  margin-bottom: 20px;
}

.services-container .service-box-1 {
  border-radius: 12px;
  background: radial-gradient(72.04% 67% at 50.04% 52.57%, #fcfce4 0, #d9fce1 100%);
}

.services-container .service-box-2 {
  border-radius: 12px;
  background: radial-gradient(108.17% 67.89% at 49.96% 50%, #dee2fe 0, #dadce8 100%)
}

/* Home Page */
/* TripPlan Page */
.trip-plan-container {
  margin-top: 50px;
  width: 60% !important;
}

.trip-plan-container .divider {
  height: 1px;
  margin: 40px 0px;
  background-color: var(--grey);
}

.trip-plan-container .form-box {
  margin-top: 50px;
}

.trip-plan-container .form-box .form-group {
  margin-bottom: 20px;
}

.trip-plan-container .form-box label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.trip-plan-container .form-box .date-picker-input {
  display: block;
}

.search-option-box {
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0;
  list-style: none;
}

.search-option-box li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.search-option-box li:last-child {
  border-bottom: none;
}

.search-option-box li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.search-option-box li.selected {
  background-color: #f0f0f0;
  cursor: pointer;
}

.trip-plan-container ul.search-option-box li {
  list-style-type: none;
}

.trip-plan-container .radio.budget.active {
  border: 2px solid var(--black)
}

.budget input {
  display: none;
}

.budget img {
  display: block;
}

.budget {
  border-radius: 0.375rem;
  padding: 20px;
  margin: 0 0 20px 0;
  border: 1px solid #ada9a9;
}

.form_budget {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
}
.form_budget span {
  font-weight: 600;
}

.budget img {
  width: 14%;
}

.interested label {
  display: block;
  width: 100%;
}

.interested .react-datepicker-wrapper {
  width: 100% !important;
}

.interested input {
  width: 100%;
  border-radius: 5px;
  padding: 13px;
  border: 1px solid #222;
}

.food_option input[type="checkbox"] {
  display: flex;
  float: right;
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

.food_option {
  padding-bottom: 20px;
}

input[type="radio"] {
  margin: 0 10px;
}

.budget:hover {
  border: 1px solid #000;
}
.submit_button {
  justify-content: flex-end;
  width: 100%;
  display: flex;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  z-index: 999;
  padding: 1rem 0;
  border-top: 1px solid #ada9a9;
  background: #fff;
}

.submit_button button {
  background: #000;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
}
form.form {
  padding: 0 0 70px 0;
}
.submit_button button:hover {
  background: #484848;
}
.bttns-box.mt-3 button.btn:hover {
  background: #aaa4a4;
  color:#000
}
.bttns-box.mt-3 button.btn {
  align-items: center;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  color: black;
  /* font-weight: 800; */
  font-size: 21px;

}
.header_button a:hover {
  background: #6e6e6e !important;
}
.bttns-box.mt-3 {
  justify-content: end;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 !important;
}
.day {
  display: flex;
  align-items: center;
}
.form-group input[type=checkbox] {
  accent-color: #000;
}
.form-group input[type=radio] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-clip: content-box;
  border: 1px solid hsl(0deg 0% 51.97%);
  margin: 0;
}

.form-group input[type="radio"]:checked {
  background-color: #000;
  padding: 3px;
  border: 2px solid #000;
}

.bootom_box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 70px;
}
.bootom_box .radio {
  margin-bottom: 20px;
}

.interested .error-message-box {
  margin-top: 18px;
}
/* TripPlan Page */

#footer {
  padding: 20px 0;
  text-align: center;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  background-color: #dbd7d7;
  color: #383737;
  /* position: fixed; */
}

.register {
  height: auto;
  width: 100%;
  background-color: #363434;
  display: flex;
}
.start_end {
  column-count: 2;
  margin-bottom: 15px;
}
/* Login  */
.auth-container {
  margin-top: 50px;
}

.auth-container .form-container {
  box-shadow: 0px 0px 4px 5px #ddd;
  width: 400px;
  padding: 40px;
  border-radius: 14px;
}

.auth-container .form-container h1 {
  margin-bottom: 20px;
}

.auth-container .form-container .form-group {
  margin-bottom: 12px;
}
.auth-container .form-container .error-message-box{
  color: #cb2424;
}
.error-message-box{
  color: #cb2424;
}
.auth-container .form-container .submit-btn button {
  width: 100%;
  padding: 4px;
  margin-top: 10px;
  border: 2px solid var(--black);
  background-color: var(--white);
  color: var(--black);
  border-radius: 5px;
}

.auth-container .form-container .submit-btn button:hover {
  background-color: #ddd;
  color: var(--black);
}

/* preview*/
.icon_img img {
  width: 24px;
border-radius: 5px;  
}
.map-box{
position: fixed;
right:0;
}
.element.style {
 margin-top: 45px;
}
.preview_icon {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.preview_icon img {
  margin: 0 7px;
}
.preview_page {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
 
}
.preview_box {
  border-bottom: 1px solid #e7e7e7;
  padding: 20px 0;
}
.preview_box h6 {
  font-size: 30px;
  font-weight: 400;
}
.date {
  color: #cd3c07;
  font-weight: 400;
}
.Itinerary-data li {
  list-style: none;
}
.Itinerary-data h6 {
  text-decoration: underline !IMPORTANT;
  font-size: 18px;
}
.Itinerary-data li {
  padding-bottom: 20px;
}
.read_more {
  background: #000;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  margin: 20px 0;
}
.accommodation_page {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.hotel {
  padding: 10px 0;
}
.read_more:hover {
  background: #484848;
    color: #fff;
}
.accommodation_page li {
  list-style: none;
}
.icon_img img {
  margin-right: 10px;
}
/* blog */
.image-box img{
  height: 400px;
  width: 100%;
  padding: 20px;
  margin-right: 10px;
  border-radius: 25px;
}
.blog-image{
  display: flex;
  align-items: center;
 
}
.blog-hading h1{
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}
.blog-hading{
  width: 860px;
  margin: auto;
  padding: 46px;
}

.all-image-box {
  width: 94%;
  margin: auto;
  padding-bottom: 50px;
}

.artical{
  padding: 2px 0px 0px 20px;
  font-size: large;
}

/* header*/
.navebarheader1 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding: 12px;
  z-index: 9999;
}
.navebarheader{
  top:0;
  left: 0;
} 
.header_button button{
  background: #000;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}
.header_button button:hover{
  background: #484848;
  color: #fff;
}
.header_button a{
  background: #000;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  color: #fff !important;
  border-radius: 5px;
  font-weight: 500;
}
#dropdown-basic {
  background: none;
  padding: 0px 10px !important;
}
#dropdown-basic:after {
  color: #000;
  top: 0;
}
.dropdown {
  display: flex;
}
.dropdown .dropdown-item {
  background: none;
  color: #000 !important;
  border-bottom: 1px solid #cdcdcd !important;
  border-radius: 0;
}
.dropdown-menu {
  padding: 0;
  z-index: 99 !important;
  position: relative;
}
.dropdown-menu.show {
  padding: 0;
}
.header_button a:hover{
  background: #484848;
  color: #fff;
}
.navebarheader h1.navbar-brand.nav-link a {
  text-decoration: none;
}
.navebarheader li.nav-item a {
  margin: 0 10px;
  font-weight: 500;
  font-size: 18px;
}
.navbar-brand img {
  max-width: 180px;
}
.navbar {
  padding: 0 !important;
  border-bottom: 1px solid #e7e7e7;
}
/* loader css */

.scaleLoader {
  text-align: center;
  /* align-items: center; */

  margin-top: 200px;
}

.header_button img {
  height: 44px;
}

/* new css 13-10-2023 */
.header-container a {
  text-transform: capitalize;
}

.header-container #navbarNav {
  
  margin-left: 50px;
}
.user-friendly img {
  max-width: 410px;
    align-items: center;
    margin: auto;
    display: flex;
}

.button-box button {
  border: none !important;
  display: flex;
  margin: 35px auto 0;
  background-color: #000;
  border-radius: 3px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 5px
} 
.button-box a{
  text-decoration: none;
  color: white;
  padding: 10px;
}
.button-box button:hover{
  background-color: #464444;
  color: white;

}
.img-box img {
  display: flex;
  margin: auto;
}


.start_free h1{
  text-align: center;
  font-size: 55px !important;

}
/* slider */
.aboutus-container img {
  height: 550px;
  width: 100% !important;
  object-fit: cover;
}
#footer p {
  margin-bottom: 0;
}
.map-div {
  height: 665px;
}

/*home page i pad responsive css start here */
@media screen and (max-width: 1024px) {
  .services-container .service-box-1, .services-container .service-box-2 {
    width: 44%;
}
.banner-container .banner-heading h1 {
  font-size: 42px;
}
.navebarheader1 {
  justify-content: normal !important;
}
.header_button{
  margin: 0 0 0 auto  !important;
  order: 2  !important;
}
.banner-container .button-box button {
  padding: 15px 30px;
  font-size: 16px;
}
.navbar-toggler {
  order: 3;
  margin-left: 20px;
}
.aboutus-container {
  margin-top: 0;
}
.services-container .service-box-1 .heading h1, .services-container .service-box-2 .heading h1 {
  font-size: 24px;
}
.services-container .service-box-1 .content p, .services-container .service-box-2 .content p {
  padding: 0px 10px;
  font-size: 16px;
}

.start_free h1 {
  margin-top: 30px;
  font-size: 42px !important;
}
.button-box a {
  padding: 0;
  font-size: 16px;
}
.button-box button{
  padding: 15px 30px;
}
.map-div {
  height: 396px !important;
}
}



/*home page  mobile responsive css start here */
@media screen and (max-width: 767px) {
  .user-friendly img,.img-box img {
    max-width: 100%;
}
.header_button a {
  padding: 10px 23px;
  font-size: 14px;
}
.blog-image {
  display: block;
  /* align-items: center; */
}
.image-box {
  height: auto;
}
.blog-hading{
  margin-left: 0px !important;
  width: 100% !important;
}
#footer p {
  padding: 10px !important;
}
.blog-image {
  margin-bottom: 0;
}
.image-box img {
  padding: 10px;
}
.trip-plan-container {
  width: 100% !important;
}
.budget img {
  width: 30%;
  margin: auto;
}
.form_budget {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.budget {
  text-align: center;
}
.form_budget span {
  margin-top: 10px !important;
  display: block;
}
.start_end {
  column-count: 1 !important;
}
.services-container .service-box-1, .services-container .service-box-2{
  width: 100%;
  margin: 0;
}
.start_free h1,.blog-hading h1 {
  font-size: 22px !important;
    line-height: 31px;
}
body .banner-box h1 {
  font-size: 25px !important;
  text-align: center;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.banner-text-box h1 {
  font-size: 30px !important;
  margin-bottom: 30px;
}
.img-box img {
  width: 50%;
}
.blog-hading {
  padding: 46px 0 10px 0;
}
.banner-container .banner-sub-heading h6 {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}
.banner-container .button-box {
  float: none !important;
}
.navbar-brand img {
  max-width: 130px;
}
.aboutus-container img {
  height: 250px;
}
.service-box-2 {
  margin-top: 30px !important;
}
.user-friendly img{
  max-width: 200px;
}
.services-container {
  margin-top: 0;
}
.services-container {
  overflow: hidden;
}
.map-box {
  position: relative;
  right: 0;
}
.carousel.slide .d-none {
  display: block !important;
}
.slide h1 {
  font-size: 20px !important;
}
.slide img {
  height: 250px;
}
.carousel-caption {
  top: 0;
}
.slide p {
  font-size: 14px;
  width: 100%;
  margin: 0;
}
.carousel.slide::before {
  height: 250px;
}
.about_image {
  flex-direction: column-reverse;
}
.bootom_box {
  grid-template-columns: 1fr;
}
.blog-image .artical h2 {
  font-size: 18px;
  line-height: 27px;
}
.blog-image .artical p {
  font-size: 16px;
  margin: 0 0 26px 0;
}
}


/*Trip planner page i pade responsive css start here */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .trip-plan-container {
    width: 100% !important;
}
.image-box {
  width: 100%;
  height: 473px;
}
.image-box img {
  height: auto;
}
.map-div {
  height: 500px !important;
}

.budget img {
  width: 26%;
}
.slide h1 {
  font-size: 35px !important;
}
.carousel-caption {
  top: 28%;
}
}